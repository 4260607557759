import React from "react"

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { CarouselProvider, Slider, Slide  } from "pure-react-carousel";

import styles from "assets/jss/material-kit-react/views/sections/findStyle.js"
import "pure-react-carousel/dist/react-carousel.es.css";

const useStyles = makeStyles(styles)

const FindSection = props => {
  const classes = useStyles()

  var totalItems = (props.data?.allContentfulBrand?.nodes.length ?? 0)

  return (
    <div className={classes.section}>
      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={10}
        totalSlides={totalItems}
        visibleSlides={8}
        infinite={true}
        orientation={"horizontal"}
      >
        <Slider>
          {props?.data?.allContentfulBrand?.nodes
            .filter(
              brand =>
                brand.featured === true
            )
            .map((brand, index) => (
              <Slide className={classes.siderShadow} key={index} index={index} style={{margin:"10px"}}>
                  <Link
                    to={`/search/brand/${brand.companyName.companyName}`}
                    state={{
                      brand: brand,
                      name: brand.companyName.companyName,
                      keywords: "",
                    }}
                  >
                    <img
                      style={{maxWidth:"60%"}}
                      title={brand.companyName.companyName}
                      src={brand?.logo?.file?.url}
                    />
                    <label style={{fontSize:"20px", fontWeight:"800"}}>
                      {brand.name}
                    </label>
                  </Link>
              </Slide>
            ))
          }
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default FindSection
