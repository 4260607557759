import React from "react"

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

import styles from "assets/jss/material-kit-react/views/sections/findStyle.js"
import "pure-react-carousel/dist/react-carousel.es.css";

const useStyles = makeStyles(styles)

const CategorySection = (props) => {
  const classes = useStyles();

  var totalItems = (props.data?.allContentfulLeafletCategory?.nodes?.length ?? 0) + 8;

  return (
    <div className={classes.section}>
      <h4 className={classes.title}>BROWSE BY CATEGORY</h4>

      <CarouselProvider
          naturalSlideWidth={10}
          naturalSlideHeight={11}
          totalSlides={totalItems}
          visibleSlides={8}
          infinite={true}
          orientation={"horizontal"}
        >
          <Slider>
            {props?.data?.allContentfulLeafletCategory?.nodes.map((category, index) => (
                <Slide className={classes.sider} key={index} index={index} style={{margin:"10px"}}>
                    <Link
                      to={`/search/category/${category.name}`}
                      state={{
                        leaflet: category.leaflet,
                        name: category.name,
                        keywords: "",
                      }}
                    >
                      <img 
                        title={category.name}
                        src={category.thumbnail?.fluid.src}
                      />
                      <label style={{fontSize:"20px", fontWeight:"800"}}>
                        {category.name}
                      </label>
                    </Link>
                </Slide>
              ))
            }
          </Slider>
        </CarouselProvider>
    </div>
  );
};

export default CategorySection;