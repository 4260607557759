import React from "react"

import { CarouselProvider, Slider, Slide, ImageWithZoom  } from "pure-react-carousel";
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import styles from "assets/jss/material-kit-react/views/sections/featuredStyle.js"

import "pure-react-carousel/dist/react-carousel.es.css";

const useStyles = makeStyles(styles)

const FeaturedSection = props => {
  const classes = useStyles()

  const validLeaflets = 
    props &&
    props.data &&
    props.data.allContentfulLeaflet.edges.filter(
      (leaflet) =>
        new Date(leaflet.node.validTo).getDate() >= new Date().getDate()
  );

  const slideCount = validLeaflets?.length + 5 || 0;

  return (
    <div className={classes.section}>
      <h4 className={classes.title}>LATEST DEALS</h4>

      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={14}
        totalSlides={slideCount}
        visibleSlides={4}
        orientation={"horizontal"}
        infinite={true}
        isPlaying={true}
        interval={3000}
      >
        <Slider>
          {validLeaflets && validLeaflets
            .slice(0, slideCount)
            .sort((a, b) => new Date(b.node.validTo) - new Date(a.node.validTo))
            .map((leaflet, index) => (
              <Slide className={classes.sider} key={index} index={index} style={{margin:"10px"}}>
                  <Link
                    to={`/catalogue/${leaflet.node.id}`}
                    state={{
                      leaflet: leaflet.node,
                      name: leaflet.node.title,
                      keywords: "",
                    }}
                  >
                    <img
                      height={"100%"}
                      title={leaflet.node.title}
                      src={leaflet.node.pages[0].file.url}
                    >
                    </img>
                  </Link>
              </Slide>
            ))
          }
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default FeaturedSection
