/* eslint-disable react/prop-types */
import React from "react"

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import Typography from "@material-ui/core/Typography"

import styles from "assets/jss/material-kit-react/views/sections/featuredStyle.js"

const useStyles = makeStyles(styles)

const LocationSection = props => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <h4 className={classes.title}>SPECIALS BY REGION</h4>

      <GridContainer alignItems="center" justifyContent="center">
        {props.data?.allContentfulLocation?.nodes?.map(location => (
          <GridItem key={location.node?.id} xs={12} sm={6} md={4} lg={3}>
            <br></br>
            <Card style={{ width: "100%", height: "100%", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} className={classes.card}>
              <CardActionArea>
                  <Link
                    to={`/search/region/${location.locationName}`}
                    state={{
                      leaflet: location.leaflets,
                      name: location.locationName,
                      keywords: "",
                    }}
                  >
                  <GridContainer container spacing={2}>
                    {location.leaflet.slice(0, 4).map((leaflet, index) => (
                      <GridItem item xs={6} key={index}>
                        <img
                          src={leaflet.pages[0]?.fluid.src}
                          height={"150px"}
                          alt={`Image ${index}`}
                        />
                      </GridItem>
                    ))}
                  </GridContainer>
                </Link>
              </CardActionArea>
              <Typography style={{padding:"20px"}} variant="h6" component="label">{location.locationName}</Typography>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  )
}

export default LocationSection