import { title } from 'assets/jss/material-kit-react.js'

const productStyle = {
  section: {
    padding: '15px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '15px',
    minHeight: '15px',
    textDecorationColor: '#ef3d56',
    textAlign: 'left'
  },
  description: {
    color: '#999',
  },
  sider: {
    height:'200px',
  }
}

export default productStyle
