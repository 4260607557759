import React from "react"
import classNames from "classnames"

import FeaturedSection from "./Sections/FeaturedSection.js"
import FeaturedLeaflet from "./Sections/FeaturedLeaflet.js"
import FeaturedBrandSection from "./Sections/FeaturedBrandSection.js"
import CategorySection from "./Sections/CategorySection.js"
import RegionSection from "./Sections/RegionSection.js"

import { makeStyles } from "@material-ui/core/styles"

import styles from "assets/jss/material-kit-react/views/landingPage.js"

const useStyles = makeStyles(styles)
const HomePage = props => {
  const classes = useStyles()

  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <FeaturedBrandSection data={props.data} />
          <CategorySection data={props.data} />
          <FeaturedLeaflet data={props.data} />
          <RegionSection data={props.data} />
          {/* <FeaturedSection data={props.data} /> */}
        </div>
      </div>
    </div>
  )
}

export default HomePage
