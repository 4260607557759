/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomePage from "./HomePage/HomePage"

import "assets/scss/material-kit-react.scss?v=1.8.0"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Home Page"
        description="Check out the latest online catalogues, pamphlets, deals, promotions and specials from all your favorite retails and brands, to help you save money - fast and easy."
      />
      <HomePage data={data} />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulSiteInfo {
      nodes {
        detailImage {
          gatsbyImageData
        }
        searchImage {
          gatsbyImageData
        }
        title
        terms {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulFeature {
      edges {
        node {
          id
          title
          description
          logo {
            id
            file {
              url
            }
          }
        }
      }
    }
    allContentfulBrand {
      nodes {
        id
        featured
        companyName {
          companyName
        }
        logo {
          file {
            url
          }
        }
      }
    }
    allContentfulBrandCategory {
      edges {
        node {
          id
          order
          title {
            title
          }
          brands {
            companyName {
              companyName
              id
            }
            logo {
              file {
                url
              }
            }
            website
            twitter
            facebook
            email
          }
        }
      }
    },
    allContentfulLeafletCategory {
      nodes {
        name
        thumbnail {
          fluid(maxHeight: 200, maxWidth: 200) {
            src
          }
        }
        leaflet {
          title
          validFrom
          validTo
          tags
          pages {
            description
            fluid {
              src
            }
          }
          id
          locations {
            kilometerRange
            location {
              lat
              lon
            }
          }
          brand {
            companyName {
              companyName
            }
          }
          feature {
            title
          }
          isEnabled
        }
      }
    }
    allContentfulLocation {
      nodes {
        locationName
        leaflet {
          title
          validFrom
          validTo
          tags
          pages {
            description
            fluid {
              src
            }
          }
          id
          locations {
            kilometerRange
            location {
              lat
              lon
            }
          }
          brand {
            companyName {
              companyName
            }
          }
          feature {
            title
          }
          isEnabled
        }
      }
    }
    allContentfulLeaflet(sort: { fields: validTo, order: DESC }) {
      pageInfo {
        itemCount
      }
      edges {
        node {
          title
          validFrom
          validTo
          tags
          pages {
            description
            file {
              url
            }
            id
          }
          id
          locations {
            kilometerRange
            location {
              lat
              lon
            }
          }
          brand {
            companyName {
              companyName
            }
          }
          feature {
            title
          }
          isEnabled
        }
      }
      distinct(field: title)
    }
  }
`

export default IndexPage
